const dispatchQuasarEvent = <T>(
	name:
		| "quasar:openQuickCashModal"
		| "quasar:openLoginModal"
		| "quasar:openCashModal"
		| "quasar:openCashPaymentModal"
		| "quasar:openGame"
		| "quasar:openPromotion"
		| "quasar:openGamePopup"
		| "quasar:mounted"
		| "quasar:loginBySocial"
		| "quasar:navigate"
		| "quasar:openLockedGame"
		| "quasar:openSupport"
		| "quasar:remindPassword"
		| "quasar:openСodeConfirm"
		| "quasar:loginUser"
		| "quasar:registerUser"
		| "quasar:gaSignupFormSubmit"
		| "quasar:closeModalSuccess"
		| "quasar:updatesBeforeRequestSend"
		| "quasar:openWelcomePopup"
		| "quasar:failedPhoneRegistration"
		| "quasar:gaApplyPromoCode"
		| "quasar:gaPromoCodeSuccess"
		| "quasar:gaPromoCodeFailed"
		| "quasar:clearPromoCodeCookie"
		| "quasar:gaChangePromoCode"
		| "quasar:gaChangeInput"
		| "quasar:openReRegistrationPopUp"
		| "quasar:loginReRegistration"
		| "quasar:reRegistrationCreateAccount"
		| "quasar:gaExitPopupShow"
		| "quasar:gaExitBackToSignup"
		| "quasar:gaExitPopupClose"
		| "quasar:gaSendVerifyLink"
		| "quasar:logoutPlayer"
		| "quasar:gaPasswordSend"
		| "quasar:gaEmailConfirmationStart"
		| "quasar:initEmailConfirmTimer"
		| "quasar:gaRegisterStart"
		| "quasar:showAndSetupPackagePopup"
		| "quasar:getGiftPopupData"
		| "quasar:gaPhoneConfirmStart"
		| "quasar:gaRegisterVerification"
		| "quasar:fbVerificationSuccess"
		| "quasar:setRewardCompleteStatus"
		| "quasar:setPlayerVerificationStatus"
		| "quasar:openCashProfilePopup"
		| "quasar:cashUpdateProfile"
		| "quasar:gaLogout"
		| "quasar:buyWinningsFromEntries"
		| "quasar:setAutoPasswordState"
		| "quasar:setProfilePhone"
		| "quasar:navigateToPage"
		| "quasar:gaRewardsTabs"
		| "quasar:gaClaimReward"
		| "quasar:gaRewardsModal"
		| "quasar:openUnlockStatusModal"
		| "quasar:openMagicBox"
		| "quasar:changeBalanceSwitch"
		| "quasar:showPiggyBankGamesModal"
		| "quasar:openCashDepositModal"
		| "quasar:setIsEmailRegistration"
		| "quasar:cookieRegistrationRemove"
		| "quasar:gaRegistrationInit"
		| "quasar:showTermDialogPopup"
		| "quasar:gaRegistrationLogout"
		| "quasar:presetPackageModalState"
		| "quasar:finishRegistrationComplete"
		| "quasar:logoutHandle"
		| "quasar:showPackageAfterAuth"
		| "quasar:openReferralPopUp"
		| "quasar:joinQuest"
		| "quasar:setGameMode"
		| "quasar:initPresetPackage"
		| "SHOW_FAILED_VERIFY"
		| "BACK_TO_METHODS"
		| "SUCCESS_PHONE_CONFIRM"
		| "SHOW_SUCCESS_VERIFY"
		| "OPEN_3DSVerification"
		| "DEPOSIT_COMPLETE"
		| "CLOSE_PROFILE"
		| "3DSResult"
		| "quasar:toggleGameMode"
		| "quasar:openCashPresetModal"
		| "quasar:setInitialOfferCookie"
		| "quasar:toggleSeason"
		| "quasar:refreshWheelOfWinsData"
		| "quasar:openChat"
		| "quasar:openSurvey"
		| "quasar:scanDocuments"
		| "quasar:openVerificationResult"
		| "PHONE_VERIFY_CLOSE",
	eventInitDict?: CustomEventInit<T>
) => window.dispatchEvent(new CustomEvent(name, eventInitDict));

export default dispatchQuasarEvent;
